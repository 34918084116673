/*
=========================================================
* Impact Design System PRO - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/impact-design-system-pro
* Copyright  Creative Tim (http://www.creative-tim.com)
* Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "bootstrap/mixins";
@import "bootstrap/functions";

// Change variables here
@import "front/variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Vendor
@import "front/vendor";

// front mixins & functions
@import "front/mixins";
@import "front/functions";

// Utilities
@import "front/reboot";
@import "front/utilities";

// Layout
@import "front/layout";

// Components
@import "front/components";

// write your custom styles here!